import { Stack, Box, Typography, Container } from "@mui/material";
import LoadingButton from "../../../../generic-components/button";
import { mmmUniversalRedirection } from "../../../../../helpers";
import useStepper from "../../../../../hooks/useStepper";
import { TRACKING_HOME_OPTIONS, mmmThemes } from "../../../../../constants";
import { useState } from "react";
import ContributionCalculatorModal from "../../shared-cost-application/modal/ContributionCalculatorModal";

const HomePageTextSection = () => {
  const { personalDetails } = useStepper();
  const [openModal, setOpenModal] = useState();

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  return (
    <Container>
      <Stack
        direction={{ xs: "column", sm: "column", md: "row", lg: "row" }}
        spacing={2}
        sx={{
          display: "flex",
        }}
        className="mt-50"
      >
        <Box
          sx={{
            flex: 0.6,
          }}
        >
          <Stack direction="column" spacing={2}>
            <Box sx={{ paddingBottom: 2, px: 3 }}>
              <Typography variant="h6" className="mt-30 header-bold-lg">
                Want to know more?
              </Typography>
              <Typography className="mt-30 home-typography dark-purple-text">
                It’s a separate pot alongside your Local Government Pension
                Scheme to help you have more money at retirement.
              </Typography>
              <Typography className="mt-30" variant="subtitle1">
                <strong className="text-bold">Why is it good?</strong>
              </Typography>
              <Typography className="home-typography dark-purple-text ">
                It’s a very cost-effective way to save for your retirement and
                is more beneficial when compared to paying standard AVCs because
                of the available Income Tax and National Insurance Contribution
                (NIC) savings.
              </Typography>
              <Typography className="mt-30" variant="subtitle1">
                <strong className="text-bold">How does it work?</strong>
              </Typography>
              <Typography className="home-typography dark-purple-text">
                The contributions are added to this extra pot via a salary
                sacrifice arrangement each payroll.
              </Typography>
              <Typography className="mt-30" variant="subtitle1">
                <strong className="text-bold">What does that mean?</strong>
              </Typography>
              <Typography className="home-typography dark-purple-text">
                If you can afford to reduce your take home pay by £100 per
                payroll, this means £138.67 will be added to your pot – an{" "}
                <strong className="primary-text">
                  instant increase of £38.67 or 38.67%!*
                </strong>
              </Typography>
              <Typography className="mt-30" variant="subtitle1">
                <strong className="text-bold">
                  How much can I put in - and how often?
                </strong>
              </Typography>
              <Typography className="home-typography dark-purple-text">
                You can choose an amount as little as £2 per payroll, up to your
                maximum contribution amount based on your salary and other
                details. You can work out your maximum contribution amount by
                using our calculator{" "}
                <span
                  className="web-link cursor-pointer underline-text"
                  onClick={handleOpenModal}
                  onKeyUp={handleOpenModal}
                >
                  here
                </span>
                .
              </Typography>
              <Typography className="mt-30" variant="subtitle1">
                <strong className="text-bold">
                  Can I change the contribution amount?
                </strong>
              </Typography>
              <Typography className="home-typography dark-purple-text">
                Yes, you can amend the contribution amount at any time.
              </Typography>
            </Box>
            <Box>
              <Typography
                sx={{ display: "block", mt: 2 }}
                className="home-typography-subtitle dark-purple-text"
              >
                *The estimated savings each payroll are not guaranteed and could
                vary depending on your circumstances and the rate at which you
                pay Income Tax and National Insurance.
              </Typography>
              <Typography
                sx={{ display: "block" }}
                className="home-typography-subtitle dark-purple-text"
              >
                These calculations are based on Income Tax at 20% (basic rate)
                and 40% (higher rate), and National Insurance Contributions at
                8% (basic rate) and 2% (higher rate).
              </Typography>
            </Box>
          </Stack>
        </Box>

        <Box
          sx={{
            flex: 0.4,
          }}
        >
          <Box
            sx={{
              backgroundColor: "white",
              boxShadow: "8px 8px 22px rgba(0, 0, 0, 0.25)",
              p: 2,
            }}
          >
            <Typography variant="h6" className="mt-10  header-bold-lg">
              Join a Webinar
            </Typography>
            <Typography className="home-typography mt-30">
              Come to our live session where you can find out everything you
              want to know about{" "}
              <span className="primary-text">Shared Cost AVCs</span>, how they
              work and how you can use it to best effect.
            </Typography>
            <Typography className="home-typography mt-30">
              Come along, listen and ask any questions you have.
            </Typography>
            <LoadingButton
              buttonTitle="Book a Place"
              trackingDetails={TRACKING_HOME_OPTIONS}
              styleClass={"book-place-btn mt-18"}
              handleClick={() => {
                mmmUniversalRedirection(
                  personalDetails?.sub_domain,
                  `/employee/webinars/list/${mmmThemes.MY_RETIREMENTS_MATTERS}/${process.env.REACT_APP_MMM_RETIREMENT_THEME_ID}`
                );
              }}
            />
          </Box>
        </Box>
      </Stack>
      <ContributionCalculatorModal
        open={openModal}
        setOpen={setOpenModal}
        applyButtonTitle="Create new Shared Cost AVC"
        convertButtonTitle="Convert existing AVC"
        islargeApplyButton={true}
        showConvertButton={true}
        showCloseButton={false}
      />
    </Container>
  );
};

export default HomePageTextSection;
